/* src/styles/aui-overrides.scss */
a.btn.btn-link-style,
a.btn.btn-link-style:focus,
a.btn.btn-link-style:active,
a.btn.btn-link-style:visited {
  color: #c10e21 !important; 
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
aui-button.btn {
  border: none !important;
}