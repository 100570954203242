@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../src/app/variables.scss";
@import '~nouislider/dist/nouislider.min.css';
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 16px;
  flex-shrink: 0;
  display: none !important;
}
label {
  display: inline-block;
  margin-bottom: 0rem;
}
/* You can add global styles to this file, and also import other style files */
body{
    margin: 0;
    font-family: Montserrat,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #222328;
    text-align: left;
    background-color: #fff;
    overflow-x: hidden;
}
.mdc-dialog__actions {
  padding: 8px 34px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  min-height: 52px !important;
  align-items: center !important;
  float: right !important;
  margin: 16px 0px 8px 0px !important;
}
.mat-mdc-dialog-actions .btn-cancel {
  margin-left: 10px !important;
  color: #c10e21 !important;
  font-size: var(--Form-fromFontSize-16, 16px) !important;
  font-weight: 500 !important;
  line-height: normal !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-line: underline !important;
}
.mat-mdc-text-field-wrapper {
  background-color: #f0f0f0; /* Replace with your desired color */
}
.mat-mdc-tab.mdc-tab {
  flex-grow: 0 !important;
}
.mat-primary.mat-mdc-tab-group,.mat-primary.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #c10e21 !important;
  --mat-tab-header-active-label-text-color: #c10e21 !important;
  --mat-tab-header-active-ripple-color: #c10e21 !important;
  --mat-tab-header-active-focus-label-text-color: #c10e21 !important;
  --mat-tab-header-active-hover-label-text-color: #c10e21 !important;
  --mat-tab-header-active-focus-indicator-color: #c10e21 !important;
  --mat-tab-header-active-hover-indicator-color: #c10e21 !important;
}

.dashboard-container{
    background: #F7F8F9;
}

.pmp-primary-btn, .pmp-primary-btn:hover{
  background-color: $primary-color;
  border-color: $primary-color;
}

.pmp-primary-btn:focus{
  box-shadow: 0 0 0 0.2rem $primary-color;
}

.register-now, .register-now-white{
    background-color: $primary-color;
    border-color: $primary-color;
    width: 200px;
    line-height: 2;
    margin-top: 40px;
    border-radius: 50px
}
.register-now-white{
    background-color: #ffffff;
    color: #C10E21;
}
.register-now:hover{
    background-color: $primary-color;
    border-color: $primary-color;
}
.primary-btn:hover{
  border-color: #D22332 !important;
  background-color: #D22338 !important;
  color: #FFF !important;
}
.primary-btn, .primary-btn:disabled{
    border-color: #D22332;
    background-color: #D22338;
    color: #FFF;

    border-radius: 50rem;
    font-size: 16px;
    padding: 15px 30px;
}

.modal-open #speed-bump{
    display: none;
}

.slider-selection, .slider-handle{
    background-color: $primary-color;
    background-image: none !important;
}

.noUi-connect{
    background: $primary-color;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $primary-color;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 10px;
    top: 4px;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .noUi-tooltip{
    border: none;
    padding: 0px;
}

.noUi-target{
    margin-top: 30px;
}

.noUi-horizontal .noUi-handle{
    height: 24px !important;
    top: -9px !important;
}

.noUi-horizontal{
    height: 10px !important;
}

.mat-expansion-panel-header.mat-expanded {
    background: #f3f3f3 !important;
}
.mat-expansion-panel-header.mat-expanded:hover {
    background: #f3f3f3 !important;  
}
::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white; /* Set to white */
}
.mat-radio-checked .mat-radio-outer-circle {
  border-color: #919191 !important;
  border: 1px solid #919191 !important;
}
.mdc-radio:focus,
.mdc-radio--focused {
  outline: none !important;
  box-shadow: none !important;
}

.mdc-radio__outer-circle {
  border-color: inherit !important; /* Reset the focus color */
}
.mdc-radio__inner-circle {
  border-color: #C10E21 !important;
}

.mdc-radio__outer-circle {
  border-color: #919191 !important;
  border-width: 1px !important;
}
.mat-tooltip::after {
  content: none !important;  /* This removes the default arrow */
}
.mdc-dialog__actions {
  float: right !important;
}
::ng-deep .mat-mdc-text-field-wrapper {
  background-color: #f0f0f0; /* Replace with your desired color */
}
.mat-row:hover{
    background-color: #f7fafd;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 99999 !important;
 }

 .cdk-overlay-connected-position-bounding-box{
     z-index: 99999 !important;
 }

 .sub-heading {
    font-size: 1.429em;
    font-weight: 500;
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal-backdrop{
    z-index: 9999;
}
.modal{
    z-index: 10000;
}

.prelogin-sub-header{
    font-size: 21px;
    margin-top: 40px;
    text-align: center;
}

.dashboard-table-container {
    flex-basis: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 8px;
    height: 334px;
}

.dashboard-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px !important;
    margin-top: -8px;
    background: transparent;

    tr.mat-header-row {
        height: 44px;
        background: #E7EAF2 0% 0% no-repeat padding-box;
    }

    th.mat-header-cell {
        text-align: left;
        font-size: 13px;
        letter-spacing: 0;
        color: #6D6E71;

        .mat-sort-header-button {
            text-align: left;
        }
    }

    tr.mat-row {
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #0000001A;
    }

    tr.mat-row.selected {
        background: #E3E5E8 0% 0% no-repeat padding-box;
    }

    td.mat-cell {
        font-size: 14px;
    }

    .mat-sort-header-button:focus {
        outline: none;
    }
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item{
    margin-right: 55px !important;
}

.advanced-pie-legend .total-label{
    font-size: 14px !important;
}

.advanced-pie-legend .total-value{
    font-size: 24px !important;
}

.advanced-pie-legend{
    margin-left: 20px !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent{
    font-size: 18px !important;
    margin-top: 15px !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value{
    font-size: 18px !important;
}

.ngx-charts .label{
    font-size: 16px !important;
}
.scrolledContainer {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
  }
  .main-section-primary-btn{
    border: 1px solid #C10E21;
    color: #C10E21;
    background-color: #fff;
    border-radius: 100px;
    width: 279px;
    height: 45px;
    margin-top: 15px;
  }
  .main-section-primary-btn:hover{
    color: #C10E21;
    text-decoration: underline;
  }
  .btn-transparent-red:hover{
    background-color: #C10E21 !important;
    color: #fff !important;
  }
  .btn-solid-red:hover:enabled{
    background-color: #a60a1a !important;
    border: 1px solid #a60a1a !important;
    text-decoration: underline;
  }
  .btn-solid-red-2:hover{
    background-color: #a60a1a !important;
    border: 1px solid #a60a1a !important;
    text-decoration: underline;
  }
  .sidebar-hover{width:80px;opacity:1}
  .toggle-hover{position:absolute; left:-25px !important}
  .button-hover{left:95px !important}
  .button-open {
      left: 275px !important;
  }
  .sidebar .aui-logo-image{
    display: none !important;
  }
  .sidebar .heart-logo-image{
    display: block !important;
  }
  .sidebar-hover .aui-logo-image{
    display: none !important;
  }
  .sidebar-hover .heart-logo-image{
    display: block !important;
  }
  .open .aui-logo-image{
    display: block !important;
  }
  .open .heart-logo-image{
    display: none !important;
  }
  .ml-20{
    margin-left: 20px;
}
.pr-30{
    padding-right: 30px;
}
.approve .btn.btn-text{
    text-decoration:none !important
}
.reject .btn.btn-text{
    text-decoration:none !important
}

.aui-tabsecondary .aui-tabtitle:hover, .aui-tabsecondary .aui-tabtitle:focus {
    text-decoration: none;
    outline: 0 !important;
    box-shadow: none !important;
}
.aui-tabsecondary .aui-sec-tab-content:focus{
    outline: 0 !important;
    box-shadow: none !important;
}
.btn.focus, .btn:focus{
    outline: 0 !important;
    box-shadow: none !important; 
}
.cursor-pointer{
    cursor: pointer;
}
.btn.btn-primary{
display: inline;
}
.form-control:focus{
    outline: 0 !important;
    box-shadow: none !important; 
}
.passwordRuleDiv{
  font-family: Montserrat, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #222328 !important;
  text-align: left !important;
.valid {
  color: #3a7d34 !important;
}

.invalid {
  color: #df0000 !important;
}
}
.link-button{
    a{
        font-size: var(--Form-fromFontSize-16, 16px);
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        color:#df0000;
        border: unset;
    }

}
.btn.btn-link-arrow {
  padding: 0px 9px 0px 0px !important;
  border: unset !important ;
}
a:focus, input:focus, button:focus, select:focus, textarea:focus{
    outline: none !important;
    box-shadow: none !important;
}
.above {
    background-color: #000;
}
.mat-select-trigger {
  box-shadow: none !important;
  outline: none !important;
}
.mat-tooltip {
    position: relative;
    &::after {
      width: 0;
      height: 0;
      content: '';
      position: absolute;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid #000;
    }
    &.below {
      overflow: initial;
      margin-top: 1rem;
      &:after {
        top: -0.5rem;
        right: calc(50% - 0.5rem);
        transform: rotate(0);
      }
    }
  
    &.above {
      overflow: initial;
      margin-bottom: 1rem;
      &:after {
        bottom: -0.5rem;
        right: calc(50% - 0.5rem);
        transform: rotate(180deg);
      }
    }
  
    &.right {
      overflow: initial;
      margin-left: 1rem;
      &:after {
        left: -0.75rem;
        top: calc(50% - 0.25rem);
        transform: rotate(270deg);
      }
    }
  
    &.left {
      overflow: initial;
      margin-right: 1rem;
      &:after {
        right: -0.75rem;
        top: calc(50% - 0.25rem);
        transform: rotate(90deg);
      }
    }
  }
  ::ng-deep a:focus,
  ::ng-deep input:focus,
  ::ng-deep button:focus,
  ::ng-deep select:focus,
  ::ng-deep textarea:focus,
  ::ng-deep .aui-dropdown:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  aui-dropdown::part(dropdown-clear-example) {
    outline: none !important;
    box-shadow: none !important;
  }
  .items-per-page {
    margin-bottom: 20px;
  }
  
  .items-per-page label {
    margin-right: 10px;
    color: #222328;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .items-per-page select {
    padding: 5px;
    color: #222328;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border:none;
  }
  .prev-btn, .next-btn{
    border-radius:  4px;
    border:  1px solid  #DBDBDB;
    background: #FFF;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  button.prev-btn:disabled, button.next-btn:disabled{
    background-color: #ccc; /* Change the background color */
    cursor: not-allowed;    /* Change the cursor to indicate it's disabled */
    opacity: 0.6;           /* Make the button appear less prominent */
    color: #666;            /* Change the text color */
    border: 1px solid #aaa; /* Adjust the border color */
  }
  .export{
    margin-right: -30px;
  }
  .table-container{
    overflow-x: hidden;
  }
  .content-bodycontents{
    border:0 !important;
  }
  .btn.cancel-btn a {
    display: flex;
    justify-content: center;
  }
.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: black !important;
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom: unset !important;
}
div.mat-mdc-select-panel {
  padding: 0px 0 !important;
}
.mat-mdc-tab {
  height: 48px !important;
  padding: 0 24px !important;
  cursor: pointer !important;
  box-sizing: border-box;
  opacity: .6 !important;
  min-width: 160px !important;
  text-align: center !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  white-space: nowrap !important;
  position: relative !important;
}
.mat-mdc-menu-panel {
  position: absolute !important;
  width: 225px !important;
  background: white !important;
  border: 1px solid #707070 !important;
  border-top: 4px solid #C10E21 !important;
  z-index: 1 !important;
}
.mat-mdc-menu-panel.dropdown-content .view {
  padding: 7px 20px !important;
  font-size: 14px !important;
  cursor: pointer !important;
}
.mat-mdc-menu-panel .view {
  padding: 5px 20px !important;
  font-size: 14px !important;
  cursor: pointer !important;
  display: flex !important;
  max-width: 222px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  justify-content: flex-start !important;
}
.mat-mdc-tab .mdc-tab__text-label {
  color:black !important
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  display: block !important;
  padding: 24px !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  overflow: auto !important;
  outline: 0 !important;
  width: 100% !important; 
  height: 100% !important;
  min-height: inherit !important;
  max-height: inherit !important;
}

.mat-step-header:hover {
  background-color: transparent !important;
}
.mat-step-header{
  background-color: transparent !important;
}
.mat-mdc-menu-content {
  padding: 2px 0 !important;
}
.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
  padding: 0px !important;
  overflow-x: hidden;
}
.mat-mdc-tab {
  font-size: 16px !important;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: unset !important;
}
.mat-mdc-dialog-actions .primary-btn{
  padding: 6px 28px !important;
}